<template>
  <div class="background">
    <div class="content">
      <template v-if="loading">
        <DGShimComponent />
      </template>
      <template v-else>
        <div class="content__pre_header">
          <router-link :to="{ name: 'DGSuiteOverview' }">{{ $t("dg_suite.details.back_link_label") }}</router-link>
        </div>
        <div class="content__header">
          <div class="content__header__image"><img :src="logo.url" width="76" height="76" alt="" /></div>
          <div class="content__header__message">
            <h1>{{ currentProduct.name }}</h1>
            <p>{{ currentProduct.slogan }}</p>
          </div>
          <ManageProduct :product="currentProduct" v-if="isManagedByDataguard" />
        </div>

        <div class="content__body">
          <div class="content__body__details">
            <img v-for="(image, i) in images" :key="i" :src="image.url" alt="" class="content__body__details__images" />
            <div v-html="markdown(currentProduct.description)" />
            <h2>{{ $t("dg_suite.details.features") }}</h2>
            <ul>
              <li v-for="(feature, i) in currentProduct.features" :key="i">{{ feature }}</li>
            </ul>
          </div>
          <DGSuiteDetailSidebar :product="currentProduct" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

import DGShimComponent from "../components/DGShimComponent";
import DGSuiteDetailSidebar from "../components/DGSuiteDetailSidebar";
import ManageProduct from "../components/ManageProduct";

// FIXME: phone number in URL needs to be ISO standard
export default {
  components: {
    DGSuiteDetailSidebar,
    ManageProduct,
    DGShimComponent,
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapState({
      currentProduct: state => state.dg_suite.currentProduct,
    }),
    isManagedByDataguard() {
      return !!this.currentProduct.feature_key;
    },
    images() {
      return this.currentProduct.images.filter(image => image.type === "detail");
    },
    logo() {
      return this.currentProduct.images.find(element => element.type === "logo");
    },
  },
  watch: {
    currentProduct(newProduct) {
      if (newProduct) {
        document.title = `${newProduct.name} | DataGuard Suite`;
      }
    },
  },
  methods: {
    ...mapActions(["fetchCurrentProduct"]),
  },
  async created() {
    try {
      await this.fetchCurrentProduct(this.$route.params.product_id);
    } finally {
      this.loading = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.background {
  background: linear-gradient(180deg, #dfe4e9 0%, #f2f4f6 38.96%);
  min-height: 90vh;
  padding-top: 1px;
}

hr {
  color: #c9d2d7;
}

.content {
  margin: 48px;
  padding: 40px;
  border: 1px solid #c9d2d7;
  box-sizing: border-box;
  box-shadow: 0px 2px 12px 1px rgba(0, 0, 0, 0.15);
  background: #ffffff;
}

.content__pre_header {
  a {
    color: #cb333b;
    padding: 4px 8px;
    display: inline-block;
    border: 1px #cb333b solid;
    margin-bottom: 16px;

    &:hover {
      text-decoration: none;
      background-color: #cb333b;
      color: #ffffff;
    }
  }
}

.content__header {
  display: flex;
  justify-content: flex-start;
  padding-bottom: 16px;
  border-bottom: #c9d2d7 solid 1px;
}

.content__header__message {
  padding-left: 26px;
  flex-grow: 1;

  h1 {
    font-size: 24px;
    line-height: 32px;
  }

  p {
    font-size: 16px;
    line-height: 24px;
  }
}

.content__body {
  display: flex;
}

.content__body__details__images {
  height: 208px;
  margin-right: 24px;
  margin-top: 18px;
  margin-bottom: 32px;
}

.content__body__details {
  max-width: 70%;

  h2 {
    margin-top: 16px;
  }

  .bold {
    font-weight: 600;
  }

  ul {
    list-style-image: url(../images/Rectangle.svg);
    li {
      padding: 8px 0;
    }
  }
}
</style>
