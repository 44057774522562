<template>
  <div class="content__body__contact">
    <div class="content__body__contact_cta_box">
      <h1>{{ $t("dg_suite.details.contact_cta") }}</h1>
      <p>{{ $t("dg_suite.details.contact_cta_subtitle") }}</p>
      <img class="content__body__contact_image" :src="provider.image" alt="" />
      <p class="h2">{{ provider.name }}</p>
      <p class="h3">{{ provider.function }}</p>
      <p class="contact_details">
        {{ $t("dg_suite.details.email") }}: <a :href="`mailto:${provider.email}`">{{ provider.email }}</a>
        <br />
        {{ $t("dg_suite.details.phone") }}: <a :href="`tel:${standardizedPhone}`">{{ provider.phone }}</a>
      </p>
    </div>
    <div class="content__body__contact_cta_box">
      <h1>{{ $t("dg_suite.details.product_overview") }}</h1>
      <button class="content__body__contact__download-button" @click="downloadProductOverview">
        <img src="../images/download_button.svg" />{{ $t("dg_suite.details.download_product_overview") }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "DGSuiteDetailSidebar",
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  computed: {
    standardizedPhone() {
      return this.product.provider.phone.replaceAll(/[^+\d]/g, "");
    },
    provider() {
      return this.product.provider;
    },
  },
  methods: {
    downloadProductOverview() {
      window.open(this.product.fact_sheet);
    },
  },
};
</script>

<style lang="scss" scoped>
.content__body__contact {
  min-width: 400px;
  padding: 20px;
  padding-right: 0;
  color: #003349;
  h1 {
    font-size: 20px;
    line-height: 28px;
  }

  .h2 {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 4px;
    font-weight: bold;
  }

  .h3 {
    font-size: 18px;
    line-height: 24px;
    color: #7e93a7;
  }

  p.contact_details {
    font-size: 16px;
    line-height: 24px;
  }

  &_cta_box {
    padding: 16px;
    background-color: #f2f4f6;
    margin-bottom: 16px;
  }
}

.content__body__contact_image {
  width: 156px;
  margin-bottom: 16px;
}
.content__body__contact__download-button {
  border: 1px solid #cb333b;
  height: 40px;
  color: #cb333b;
  font-size: 12px;
  line-height: 16px;
  background-color: white;

  img {
    padding-right: 6px;
    padding-left: 3px;
  }
}
</style>
