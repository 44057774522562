<template>
  <DgModalCustom @closeModal="closeModal" :showModal="show">
    <div class="dg-purchase-box">
      <h1 class="dg-purchase-box__title">{{ $t(`${translationsScope}.purchase.title`) }}</h1>
      <p class="dg-purchase-box__desc">
        {{ $t(`${translationsScope}.purchase.description`) }}
      </p>

      <hr />

      <div class="dg-purchase-box__subscription_details">
        <h2>{{ $t(`${translationsScope}.purchase.features_title`) }}</h2>
        <ul>
          <li v-for="key in featureKeys" :key="key">
            {{ $t(`${translationsScope}.purchase.features_object.${key}`) }}
          </li>
        </ul>
      </div>

      <DgButton @click="bookAppointment">{{ $t(`${translationsScope}.purchase.book_appointment`) }}</DgButton>
    </div>
  </DgModalCustom>
</template>

<script>
import { mapActions } from "vuex";
import { Structural, Basic } from "@/dgui-customer-components/";

export default {
  components: {
    DgModalCustom: Structural.DgModalCustom,
    DgButton: Basic.DgButton,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      translationsScope: "dg_suite.consent_management",
    };
  },
  computed: {
    featureKeys() {
      return Object.keys(this.$t(`${this.translationsScope}.purchase.features_object`));
    },
  },
  methods: {
    bookAppointment() {
      window.open(this.$t(`${this.translationsScope}.purchase.book_appointment_url`));
    },
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss" scoped>
.dg-purchase-box {
  margin-top: -56px;

  &__title {
    line-height: 1.4;
  }

  &__desc {
    color: #003349;
    margin: 0;
  }

  hr {
    border: 0;
    border-bottom: 1px solid #c9d2d7;
    margin: 28px 0;
  }

  .dg-button {
    display: block;
    margin: 24px auto;
  }

  &__subscription_details {
    padding: 24px;
    text-align: center;
    background: #f2f4f6;

    h2 {
      margin-bottom: 16px;
      font-size: 14px;
      line-height: 24px;
      color: #7e93a7;
      font-weight: normal;
    }

    ul {
      list-style: none;
      display: inline-block;
    }

    li {
      width: auto;
      padding-left: 33px;
      text-align: left;

      background: url(../../images/consent_management/checkmark.svg);
      background-repeat: no-repeat;

      & + li {
        margin-top: 11px;
      }
    }
  }
}
</style>
