<template>
  <div class="consent_management__buy_configure">
    <div v-if="isActivated">
      <DgButton @click="$router.push({ name: productConfiguration })">{{ $t("dg_suite.details.configure") }}</DgButton>
    </div>
    <div v-else>
      <DgButton @click="requestAccess">{{ $t("dg_suite.details.request_access") }}</DgButton>
      <component
        :is="purchaseProductComponent"
        :product="product"
        :show="isPurchaseModalShown"
        @close="onPurchaseModalClosed"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import PurchaseCookieManagerModal from "../components/PurchaseModals/PurchaseCookieManagerModal";
import PurchaseModal from "../components/PurchaseModals/PurchaseModal";

export default {
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  components: {
    PurchaseCookieManagerModal,
    PurchaseModal,
  },
  data() {
    return {
      isPurchaseModalShown: false,
    };
  },
  computed: {
    ...mapGetters(["isCustomerFeatureActivated"]),
    isActivated() {
      return this.isCustomerFeatureActivated(this.product.feature_key);
    },
    productConfiguration() {
      return `${this.pascaledFeatureName}Overview`;
    },
    purchaseProductComponent() {
      const componentName = `Purchase${this.pascaledFeatureName}Modal`;

      if (!this.$options.components[componentName]) {
        return "PurchaseModal";
      }

      return componentName;
    },
    pascaledFeatureName() {
      return this.product.feature_key
        .match(/[a-z]+/gi)
        .map(word => word.charAt(0).toUpperCase() + word.substr(1).toLowerCase())
        .join("");
    },
  },
  methods: {
    requestAccess() {
      const { feature_key } = this.product;
      // NOTE: Currently, we're only redirecting the whistleblowing product to the profile area, however, it is not
      //       unlikely that we'll use a similar approach for other features as well.
      const isCrosssellableViaProfile = feature_key === "whistleblowing";

      if (isCrosssellableViaProfile) {
        this.$router.push({ name: "Profile", params: { package: feature_key } });
        return;
      }

      this.isPurchaseModalShown = true;
    },
    onPurchaseModalClosed() {
      this.isPurchaseModalShown = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.consent_management__buy_configure {
  align-self: flex-end;
}
</style>
