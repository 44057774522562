<template>
  <DgModalCustom @closeModal="closeModal" :showModal="show">
    <div class="dg-purchase-box">
      <h1 class="dg-purchase-box__title">{{ $t(`${translationsScope}.purchase.title`) }}</h1>
      <p class="dg-purchase-box__desc">
        {{ $t(`${translationsScope}.purchase.description`) }}
      </p>

      <hr />

      <div class="dg-purchase-box__subscription_details">
        <h2>{{ $t(`${translationsScope}.purchase.features_title`) }}</h2>
        <ul>
          <li v-for="key in featureKeys" :key="`${key}-pm`">
            {{ $t(`${translationsScope}.purchase.features_object.${key}`) }}
          </li>
        </ul>
      </div>

      <p class="dg-purchase-box__price">
        {{ $t(`${translationsScope}.purchase.contact_us_for_price`) }}
      </p>

      <div class="dg-purchase-box__buttons">
        <DgButton class="contact-text__button" variant-style="outline" @click="openContactForm">
          {{ $t(`${translationsScope}.purchase.contact_sales`) }}
        </DgButton>
      </div>
    </div>
  </DgModalCustom>
</template>

<script>
import { Structural } from "@/dgui-customer-components/";

export default {
  components: {
    DgModalCustom: Structural.DgModalCustom,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      contactSalesURL:
        "https://outlook.office365.com/owa/calendar/CustomerSuccess1@dataguard.de/bookings/s/8jx0ij0VRU-aHr99KG6VZw2",
    };
  },
  computed: {
    featureKeys() {
      return Object.keys(this.$t(`${this.translationsScope}.purchase.features_object`));
    },
    translationsScope() {
      return `dg_suite.${this.product.feature_key}`;
    },
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    openContactForm() {
      window.open(this.contactSalesURL);
    },
  },
};
</script>
<style lang="scss" scoped>
.dg-purchase-box {
  margin-top: -56px;

  &__success {
    text-align: center;

    & > span {
      display: block;
      height: 72px;
      margin-bottom: 24px;
      background: url(../../images/consent_management/checkmark.svg);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
    }
    & > p {
      max-width: 60%;
      margin: 0 auto 16px auto;
    }
  }

  &__error {
    padding: 8px;
    background-color: #f5d6d8;
    color: #cb333b;
    margin-bottom: 16px;
    display: block;
  }

  &__title {
    line-height: 1.4;
  }

  &__desc {
    color: #003349;
    margin: 0;
  }

  hr {
    border: 0;
    border-bottom: 1px solid #c9d2d7;
    margin: 28px 0;
  }

  &__price {
    color: #cb333b;
    font-size: 20px;
    line-height: 28px;
    margin-top: 28px;
    text-align: center;
  }

  &__buttons {
    display: flex;
    justify-content: center;
    margin: 24px 8px;
  }

  &__subscription_details {
    padding: 24px;
    text-align: center;
    background: #f2f4f6;

    h2 {
      margin-bottom: 16px;
      font-size: 14px;
      line-height: 24px;
      color: #7e93a7;
      font-weight: normal;
    }

    ul {
      list-style: none;
      display: inline-block;
    }

    li {
      width: auto;
      padding-left: 33px;
      text-align: left;

      background: url(../../images/consent_management/checkmark.svg);
      background-repeat: no-repeat;

      & + li {
        margin-top: 11px;
      }
    }
  }
}
</style>
